.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

.skill-box {
  max-width: 500px;
  margin: 25px 0;
}

.skill-box .skill-bar {
  height: 16px;
  width: 100%;
  border-radius: 6px;
  margin-top: 6px;
  background: #fff;
}

.skill-box .skill-per {
  position: relative;
  display: block;
  height: 100%;
  border-radius: 6px;
  background: linear-gradient(to left, #697ea3, #abaaaa);
  animation: progress 0.4s ease-in-out forwards;
  opacity: 0;
  z-index: 10;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
.skill-per .tooltip {
  position: absolute;
  right: -14px;
  top: -28px;
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  padding: 2px 6px;
  border-radius: 3px;
  background: gray;
  z-index: 1;
}

.tooltip::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -2px;
  height: 10px;
  width: 10px;
  z-index: -1;
  background-color: gray;
  transform: translateX(-50%) rotate(45deg);
  animation: move 5s linear infinite;
}

.storageContainer {
  position: relative;
  width: 93px; /* 調整容器的寬度 */
  height: 95px; /* 調整容器的高度 */
  background-color: #a2d9ff;
}

.storageImg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.wave {
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
}
